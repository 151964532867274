
import { CloudStorage } from "@/types";
import apiFetch from "@/utils/request";
import { Vue } from "vue-class-component";

class CreateCloudStorage extends Vue {
  cloudStorage: CloudStorage = {
    prefix: "",
    path: "",
    version: "",
  };
  error: string = "";
  async save() {
    try {
      await this.createCloudStorage();
      this.$router.push({ path: "/cloud-storage" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async createCloudStorage() {
    const newCloudStorage: CloudStorage = { ...this.cloudStorage };
    await apiFetch.cloudStorage.createCloudStorage(newCloudStorage);
  }
}
export default CreateCloudStorage;
