<template>
  <div class="form">
    <!-- cloud storage form component -->
    <div id="cloud-storage-form" class="form">
      <h2>Creating New Cloud Storage Location</h2>
      <div v-if="error" class="err-msg">{{ error }}</div>
      <div class="form-content">
        <div class="form-input">
          <label for="cloud-storage-prefix"> Cloud Storage Prefix: </label>
          <input
            id="cloud-storage-prefix"
            v-model="cloudStorage.prefix"
            name="cloud_storage_prefix"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="cloud-storage-path"> Cloud Storage Path: </label>
          <input
            id="cloud-storage-path"
            v-model="cloudStorage.path"
            name="cloud_storage_path"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="cloud-storage-version">
            Cloud Storage Code Version:
          </label>
          <input
            id="cloud-storage-version"
            v-model="cloudStorage.version"
            name="cloud_storage_version"
            type="text"
          />
        </div>
        <button class="save-btn" @click="save">Save</button>
      </div>
    </div>
    <!-- cloud storage form component -->
  </div>
</template>

<script lang="ts">
import { CloudStorage } from "@/types";
import apiFetch from "@/utils/request";
import { Vue } from "vue-class-component";

class CreateCloudStorage extends Vue {
  cloudStorage: CloudStorage = {
    prefix: "",
    path: "",
    version: "",
  };
  error: string = "";
  async save() {
    try {
      await this.createCloudStorage();
      this.$router.push({ path: "/cloud-storage" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async createCloudStorage() {
    const newCloudStorage: CloudStorage = { ...this.cloudStorage };
    await apiFetch.cloudStorage.createCloudStorage(newCloudStorage);
  }
}
export default CreateCloudStorage;
</script>
